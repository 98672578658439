import React, { useEffect, useState } from 'react'
import styles from './NewsOverview.module.scss'

import {
  Row,
  Col,
  Container,
  FancyShow,
  handleImgixUrl
} from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import Img from 'gatsby-image'
import cn from 'classnames'
import { formatDateString } from '@src/utils'
import { useGlobalData } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

const newsCategories = ['Coda Terminal']

export default ({ title }) => {
  const { allIsNews, allEnNews } = useGlobalData()
  const { t, language } = useLanguage()
  const queriedNews = language === 'is' ? allIsNews : allEnNews
  
  const [noItems, setNoItems] = useState(12)
  const [category, setCategory] = useState(null)
  const [noNews, setNoNews] = useState(queriedNews ? queriedNews.length : 0)
  const [newsArray, setNewsArray] = useState(queriedNews ? queriedNews.slice(0, noItems) : [])
  
  useEffect(() => {
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const cat = queryParams.category;
    if(cat && newsCategories.includes(cat)) {
      setNewsArray(newsArray.filter(n => n.category === cat))
      setNoNews(newsArray.length)
      setCategory(cat)
    }
  },[])

  useEffect(() => {
    setNewsArray(queriedNews ? queriedNews.slice(0, noItems) : [])
  },[noItems])
  
  return (
    <FancyShow>
      <Container>
        <Row>
          <Col>
            <h2 className={cn(
              'mt-2',
              styles.title
            )}>{title} {category && `- ${category}`}</h2>
          </Col>
        </Row>
        <Row>
          {noNews > 0 &&
            newsArray.map((item, key) => {
              const {
                date,
                pageTitle,
                url,
                image
              } = item

              return (
                <NewsFootnote
                  key={key}
                  pageTitle={pageTitle}
                  date={date}
                  image={image}
                  url={url}
                />
              )
            })
          }
        </Row>
        {noNews > noItems &&
          <Row>
            <Col col={12}>
              <button
                onClick={() => setNoItems(noItems + 6)}
                className={cn(
                  styles.button,
                  'button button--primary w-100'
                )}>
                {t(`buttons|load more label`)}
              </button>
            </Col>
          </Row>
        }
      </Container>
    </FancyShow>
  )
}

const NewsFootnote = ({
  pageTitle,
  date,
  url,
  image
}) => {
  return (
    <Link
      to={url}
      className={cn(
        'col-md-4 link--card',
        styles.card
      )}
    >
      {image.length > 0 &&
        <FeaturedImage
          image={image}
        />
      }
      <p className={cn(
        styles.date,
        'eyebrow mb-0'
      )}>
        {formatDateString(date)}
      </p>
      <p className='parag-big white'>{pageTitle}</p>
    </Link>
  )
}

const FeaturedImage = ({ image }) => {
  const { isDesktop } = useGlobalData()
  const fluid = isDesktop
    ? handleImgixUrl(image[0].featured_size.url).gatsbyImageFixed()
    : handleImgixUrl(image[0].mobile_feature.url).gatsbyImageFixed()
  return (
    <Img fluid={fluid} loading='lazy' />
  )
}
